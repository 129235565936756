/* .profile_img {
  border: 5px solid #7bb041; 
} */
.profile_img img{
  width: 314px;
  height: 268px;
  object-fit: contain;
}
.profile_text {
  color: #7bb041;
}
.profile_map iframe {
  width: 100%;
  height: 300px;
}

.profile_details {
  border: 2px solid #a3a3a3;
  border-radius: 10px;
}
