.about_heading {
  color: #7bb041;
  font-size: 36px;
  font-weight: 700;
}
.about-title h3{
  color: #7bb041;
  font-size: 36px;
  font-weight: 700;
}
.about-title p {
  color: #a3a3a3;
  font-family: "Inria Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.about-spacing {
  padding-top: 80px ;
  padding-bottom: 80px;
}

@media (max-width: 767.98px) {
  .about-spacing {
    padding-top: 80px;
    padding-bottom: 10px;
  }
}