body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inria Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

code {
  font-family: "Inria Sans", monospace;
}

.bg-main {
  background: #d9d9d9;
}

.btn {
  border-radius: 0px !important;
}

.btn-secondary {
  background-color: #7bb041 !important;
  border-radius: none !important;
  border-color: transparent !important;
}

.title h2 {
  color: #009344;
  font-family: "Inria Sans";
  font-size: 39px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.title h5 {
  color: #009344;
  font-family: "Inria Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sy-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.sb-80 {
  padding-bottom: 80px;
}

.st-80 {
  padding-top: 80px;
}

footer {
  background: #009344;
}

footer p {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

@media (max-width: 575px) {
  .title h2 {
    font-size: 25px;
  }

  .title h5 {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .title h2 {
    font-size: 40px;
  }

  .title h5 {
    font-size: 30px;
  }
}