.forget_section{
    padding: 150px 0px !important;
}
.forget_box{
    border: 1px solid #000;
}
.forget_sub_box input{
    width: 100%;
    padding: 8px;
    border: 1px solid #dee2e6;
}
.forget_sub_box input:focus-visible{
    outline: none;
}
.forget_button button{
    padding: 10px ;
    min-width: 100%;
    border: transparent;
    background-color: #7bb041;
}

@media (min-width:375px) {
    .container{
        width: 100%;
    }
    .forget_section{
        padding: 50px 0px;
    }
}
@media (max-width: 575px) {
    .container{
        width: 100%;
        padding: 0px 30px;
    }
    .forget_section{
        padding: 50px 0px;
    }

}
@media (min-width:576px) {
    .container{
        width: 540px;
        padding: 0px 30px;
    }
    .forget_section{
        padding: 50px 0px !important;
    }
}
@media (min-width:992px) {
    .container{
        width: 960px;
    }
    .forget_section{
        padding: 150px 0px !important;
    }
}