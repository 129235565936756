.verify img{
    margin: 0 auto;
}
.verify{
    overflow: hidden;
}
.verify .card {
    padding:20px ;
}
.verify .card a{
    background-color: #7bb041;
    border: none;   
}