.Foundation-content h4 {
  margin-bottom: 10px;
  color: #396f7b;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Foundation-content h2 {
  color: #396f7b;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Foundation-content {
  border-right: 4px solid #7bb041;
}
.Foundation-img img {
  width: 230px;
  max-width: 100%;
}
@media (max-width: 575px) {
  .Foundation-content {
    border-right: none;
  }
  .Foundation-content h2 {
    font-size: 35px;
  }
  .Foundation-content h4 {
    margin-bottom: 10px;
    font-size: 25px;
  }
}
@media (min-width:576px) {
   .Foundation-content h2 {
    font-size: 32px;
  }
  .Foundation-content h4 {
    margin-bottom: 10px;
    font-size: 22px;
  }
  .Foundation-img img {
    width: 220px;
  }
  
}
@media (min-width: 768px) {
  .Foundation-content {
    border-right: 4px solid #7bb041;
  }
  .Foundation-content h2 {
    font-size: 32px;
  }
  .Foundation-content h4 {
    margin-bottom: 10px;
    font-size: 22px;
  }
  .Foundation-img img {
    width: 220px;
  }
}
@media (min-width: 992px) {
  .Foundation-content h2 {
    font-size: 40px;
  }
  .Foundation-content h4 {
    margin-bottom: 10px;
    font-size: 22px;
  }
  .Foundation-img img {
    width: 240px;
}
}
@media (min-width:1200px) {
  .Foundation-content h2 {
    font-size: 50px;
}
.Foundation-img img {
  width: 280px;
}
.Foundation-content h4 {
  margin-bottom: 10px;
  font-size: 25px;
}
  
}
@media (min-width:1400px){
  .Foundation-content h2 {
    font-size: 50px;
}
.Foundation-img img {
  width: 280px;
}
}
