.login_form {
  border: 1px solid #a3a3a3;
}

.form-check-input[type="checkbox"] {
  border-color: #7bb041;
  border-radius: 0px !important;
}

.form-control:focus {
  border-color: #7bb041 !important;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(123, 176, 65, 0.25) !important;
}

.form-check label {
  color: #a3a3a3;
}

input::placeholder {
  color: #a3a3a3 !important;
  font-size: 14px;
}

.forget a {
  color: #a3a3a3;
  text-decoration: none;
}

.login {
  margin-top: 55px;
}

.active-bar {
  background-color: #7bb041;
  border: 1px solid #7bb041;
  color: white;
}

.non-active-bar {
  background-color: white;
  border: 1px solid #a3a3a3;
  color: #a3a3a3;
}

.login-button {
  height: 50px;
  width: 143px !important;
  border-radius: 20px 0px 0px 20px;
}

.sign-button {
  height: 50px;
  width: 143px !important;
  border-radius: 0px 20px 20px 0px;
}

.y-spacing {
  margin-top: 120px !important;
  margin-bottom: 5px !important;
}

@media (max-width: 767.98px) {
  .y-spacing {
    margin-top: 105px !important;
    margin-bottom: 0px !important;
  }
}

.action_btn {
  border-radius: 4px;
  color: #fff !important;
  background: #7bb041;
  border: 1px solid #7bb041 !important;
  padding: 10px 20px;
  width: 100%;
}

.back_btn {
  border-radius: 4px;
  color: #7bb041 !important;
  background: white;
  border: 1px solid #7bb041 !important;
  padding: 10px 20px;
  width: 100%;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #7bb041;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 47%;
  transform: translate(-50%, -47%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#progressbar {
  margin-bottom: 30px;
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  width: 33.33%;
  float: left;
  position: relative;
  letter-spacing: 1px;
}
#progressbar i{
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  width: 33.33%;
  float: left;
  position: relative;
  letter-spacing: 1px;
  line-height: 25px;
}
#progressbar i::after{
  content: '';
  width: 94%;
  height: 2px;
  background: white;
  position: absolute;
  left: -47%;
  top: 11px;
  z-index: 0; 
}
#progressbar li:before {
  content: '';    
  width: 24px;
  height: 24px;
  line-height: 26px;
  display: block;
  font-size: 12px;
  color: #333;
  background: white;
  border: 1px solid #ee0979;
  border-radius: 25px;
  margin: 0 auto 10px auto;
  display: flex;
  justify-content: center;
}
#progressbar li:after {
  content: '';
  width: 94%;
  height: 2px;
  background: rgb(0, 0, 0);
  position: absolute;
  left: -47%;
  top: 11px;
  z-index: 0;
}

#progressbar li:first-child:after {
  content: none;
}
#progressbar li.active:before, #progressbar li.active:after {
  color: white;
}
.round{
  position: absolute;
  content: '';
  width: 17px;
  height: 17px;
  left: 83px;
  top: 4px;
  background-color: #ee0979;
  border-radius: 50%;
  z-index: 1;
}

@media (min-width:375px) {
  .container{
      width: 100%;
  }
  #progressbar i::after {
      left: -53%;
  }
  #progressbar li:after {
      left: -42%;
  }
  .round {    
      left: 83px;
      top: 4px;
  }
}
@media (max-width: 575px) {
  .container{
      width: 100%;
      padding: 0px 30px;
  }
  #progressbar i::after {
      left: -58%;
  }
  #progressbar li:after {
      left: -38%;
  }
}
@media (min-width:576px) {
  .container{
      width: 540px;
      padding: 0px 30px;
  }
  .round {    
      left: 72px;
      top: 4px;
  }
  #progressbar i::after {
      left: -53%;
  }
  #progressbar li:after {
      left: -42%;
  }
}
@media (min-width:768px) {
  .container{
      width: 720px;
      padding: 0;
  }
  .round {    
      left: 112px;
      top: 4px;
  }
  #progressbar i::after {
      left: -51%;
  }
  #progressbar li:after {
      left: -44%;
  }
}
@media (min-width:992px) {
  .container{
      width: 960px;
  }
  .round {    
      left: 152px;
      top: 4px;
  }
  #progressbar i::after {
      left: -49%;
  }
  #progressbar li:after {
      left: -45%;
  }
}
@media (min-width:1200px) {
  .container{
      width: 1140px;
  }
  .round {    
      left: 182px;
      top: 4px;
  }
  #progressbar i::after {
      left: -48%;
  }
  #progressbar li:after {
      left: -46%;
  }
}
@media (min-width:1400px) {
  .container{
      width: 1320px;
  }
  .round {    
      left: 211px;
      top: 4px;
  }
  
}



.login_form{
  margin-top: 100px !important;
}

.wizard .nav-tabs {
  position: relative;
  margin-bottom: 0;
  border-bottom-color: transparent;
  top: 40px;
}

.wizard > div.wizard-inner {
  position: relative;
  margin-bottom: 50px;
  text-align: center;
}

.connecting-line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 75%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 15px;
  z-index: 1;
}

.wizard .nav-tabs > li.active > a,
.wizard .nav-tabs > li.active > a:hover,
.wizard .nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}

span.round-tab {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 16px;
  color: #0e214b;
  font-weight: 500;
  border: 1px solid #ddd;
}
span.round-tab i {
  color: #555555;
}
.wizard li.active span.round-tab {
  background: #0db02b;
  color: #fff;
  border-color: #0db02b;
}
.wizard li.active span.round-tab i {
  color: #5bc0de;
}
.wizard .nav-tabs > li.active > a i {
  color: #0db02b;
}

/* .wizard .nav-tabs > li {
  width: 25%;
} */

.wizard li:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: red;
  transition: 0.1s ease-in-out;
}

.wizard .nav-tabs > li a {
  width: 30px;
  height: 30px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
  background-color: transparent;
  position: relative;
  top: 0;
}
.wizard .nav-tabs > li a i {
  position: absolute;
  top: -15px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  left: 50%;
  transform: translate(-11%, -50%);
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

.wizard .nav-tabs > li a:hover {
  background: transparent;
}

.wizard .tab-pane {
  position: relative;
  padding-top: 20px;
}

.wizard h3 {
  margin-top: 0;
}
.prev-step,
.next-step {
  font-size: 13px;
  padding: 8px 24px;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
}
.next-step {
  background-color: #0db02b;
}
.skip-btn {
  background-color: #cec12d;
}
.step-head {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}
.term-check {
  font-size: 14px;
  font-weight: 400;
}
.modal-body button{
  background-color: #7bb041;
  border: none;
}

.modal-body button:hover{
  background-color: #3b690b;
  border: none;
}
.modal-body img{
  width: 40px;
  margin: 0 auto;
}

.lowercase-input {
  text-transform: lowercase;
}