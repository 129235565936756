.filter_box {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}

.filter {
  margin-top: 155px;
}

.filter_title img {
  width: 20.25px;
  height: 18.75px;
}

.filter .form-select {
  border-color: transparent !important;
}

.filter .card .card-body {
  background-color: #7bb041;
  color: #fff !important;
}

.filter .card {
  border: 5px solid #7bb041;
}

.gender_btn {
  padding: 5px 8px;
  background: #7bb041;
  color: #fff;
  text-decoration: none;
  border-radius: 50px;
}

.filter_btn {
  width: 100%;
  background: #7bb041;
  border: none !important;
  color: #fff;
  border-radius: 50px;
  padding: 10px 26px;
  font-size: 18px;
}
.profile_card_img {
  height: 250px;
  object-fit: cover;
  border-radius: 0px !important;
}

.profile_card {
  cursor: pointer;
}

.filter_img{
  cursor: pointer;
}