.cont_info button{
  width: 100%;
  text-align: center; 
}
.cont_info p{
  font-family: "Inria Sans";
font-size: 26px;
color: #000;
}
@media (max-width:575px) { 
  .cont_info p{font-size: 20px;  } 
}
/* @media (min-width: 768px){
  .Foundation-content{border-right: 4px solid #7BB041;  }
  .Foundation-content h2{font-size: 35px;}
  .Foundation-content h4 {margin-bottom: 10px;font-size: 25px;}
  .Foundation-img img{    width: 280px;}
}
@media (min-width: 992px){ 
  .Foundation-content h2{font-size: 50px;}
  .Foundation-content h4 {margin-bottom: 10px;font-size: 25px;}
} */
.contact{
  margin-top: 150px;
  margin-bottom: 50px;
}
.cont_info button{
  background: #7BB041 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 10px;
}
.cont_info button img{
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}