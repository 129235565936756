.form-select {
  border-radius: 15px;
  background: #f5f5f5;
  border-color: #000 !important;
}
.search_btn {
  border-radius: 4px;
  color: #fff !important;
  background: #7bb041;
  border: 1px solid #7bb041 !important;
  padding: 0px 20px;
  min-height: 38px;
}

.form-select:focus {
  border-color: #7bb041 !important;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(123, 176, 65, 0.25) !important;
}

.form-select option {
  background-color: rgb(255, 255, 255) !important;
  border: none !important;
}

.form-select option[selected] {
  background-color: #7bb041 !important;
  color: #000;
}

.background_box{
  /* padding: 20px; */
  border-radius: 100px;
}
.border_input{
  border: 10px solid #7ab0415b;
  border-radius: 100px;
}
.search_btn{
  padding: 20px;
  border-radius: 0px 42px 42px 0px;
  margin-right: -0.5px;
  min-width: 100px;
}
.react-select__control{
  border-color: transparent !important;
}
/* .button_sub{
  margin-right: -3px;
} */

.form-select {
  border-radius: 15px;
  background: #f5f5f5;
  border-color: #000 !important;
}

.search_btn {
  border-radius: 4px;
  color: #fff !important;
  background: #7bb041;
  border: 1px solid #7bb041 !important;
  padding: 0px 20px;
  min-height: 38px;
}

.form-select:focus {
  border-color: #7bb041 !important;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(123, 176, 65, 0.25) !important;
}

.form-select option {
  background-color: rgb(255, 255, 255) !important;
  border: none !important;
}

.form-select option[selected] {
  background-color: #7bb041 !important;
  color: #000;
}

.background_box {
  /* padding: 20px; */
  border-radius: 100px;
}

.border_input {
  border: 10px solid #7ab0415b;
  border-radius: 100px;
}

.search_btn {
  padding: 20px;
  border-radius: 0px 42px 42px 0px;
  margin-right: -0.5px;
  min-width: 100px;
}

.react-select__control {
  border-color: transparent !important;
}

/* .button_sub{
  margin-right: -3px;
} */
@media (min-width:323px){
  
  .border_input {
    border-radius: 0px;
  }
  .background_box {
    border-radius: 0px;
  }
  .search_btn {
    padding: 20px 240px;
    border-radius: 0;
    content: '';
    left: -3px;
    top: 170px;
}
  .react-select__control{
    padding: 20px;
  }
  .border_boottom{
    border-bottom: 1px solid #ccc !important;
  }
  .banner_background {
    padding: 130px 0px;
  }
}
@media (max-width: 767.98px) {
  .banner-spacing {
    padding-top: 80px;
    padding-bottom: 0px;
  }
}
@media (min-width:375px){
  
  .border_input {
    border-radius: 0px;
  }
  .background_box {
    border-radius: 0px;
  }
  .search_btn {
    padding: 20px 240px;
    border-radius: 0;
    content: '';
    left: -3px;
    top: 170px;
}
  .react-select__control{
    padding: 20px;
  }
  .border_boottom{
    border-bottom: 1px solid #ccc !important;
  }
  .banner_background {
    padding: 130px 0px;
  }
}
@media (max-width:575px){
  .border_input {
    border-radius: 0px;
  }
  .background_box {
    border-radius: 0px;
  }
  .search_btn {
    padding: 10px 60px;
    border-radius: 0;
    content: '';
    left: 100px;
    top: 170px;
}
  .react-select__control{
    padding: 5px;
  }
  .border_boottom{
    border-bottom: 1px solid #ccc !important;
  }
  .banner_background {
    padding: 130px 0px;
  }
}
@media (min-width:400px){
  .search_btn {
    padding: 20px 145px;
    border-radius: 0;
    content: '';
    left: 20px;
    top: 185px;
}
}
@media (min-width:420px){
  .search_btn {
    padding: 20px 155 px;
    border-radius: 0;
    content: '';
    left: 20px;
    top: 290px;
}
}
@media (min-width:450px){
  .search_btn {
    padding: 20px 170px;
    border-radius: 0;
    content: '';
    left: 65px;
        top: 179px;
}
}
@media (min-width:560px) {
  .search_btn {
    padding: 20px 230px;
    border-radius: 0;
    content: '';
    left: 20px;
    top: 290px;
}
}
@media (min-width:575px){
  .search_btn {
    padding: 20px 235px;
    border-radius: 0;
    content: '';
    left: 20px;
    top: 290px;
}
}
@media (min-width:576px){
  .border_input {
    border-radius: 0px; 
  }
  .background_box {
    border-radius: 0px;
  }
  .search_btn {
    padding: 20px 215px;
    border-radius: 0;
    content: '';
    left: 20px;
    top: 200px;
}
  .react-select__control{
    padding: 10px;
  }
  .border_boottom{
    border-bottom: 1px solid #ccc !important;
  }
  .banner_background {
    padding: 130px 0px;
  }
}
@media (min-width:768px) {
  .border_input {
    border-radius: 0px;
  }
  .background_box {
    border-radius: 0px;
  }
  .search_btn {
    padding: 20px 300px;
    border-radius: 0;
    content: '';
    left: 28px;
    top: 270px;
    border-radius: 0 0px 0px 0px;
}
  .react-select__control{
    padding: 20px;
  }
  .border_boottom{
    border-bottom: 1px solid #ccc !important;
  }
  .banner_background{
    padding: 200px 0px;
  }
  
}
@media (min-width:992px){
  .border_input {
    border-radius: 0px;
  }
  .border_input {
    border-radius: 100px;
  }
  .background_box {
    border-radius: 100px;
    padding: 14px;
  }
  .react-select__control{
    padding: 0px;
  }
  .search_btn {
    padding: 21px 20px;
    border-radius: 0;
    content: '';
    left: 842px;
    top: 0px; 
    border-radius: 0 40px 40px 0px;
  }
  .border_boottom{
    border-bottom:none !important;
  }
}
@media (min-width:1200px){
  .react-select__control{
    padding: 0px;
  }
  .border_input {
    border-radius: 100px;
  }
  .background_box {
    border-radius: 100px;
    padding: 14px;
  }
  .react-select__control{
    padding: 0px;
  }
  .search_btn {
    padding: 21px 30px;
    border-radius: 0;
    content: '';
    left: 1013px;
    top: 0px;
    border-radius: 0 40px 40px 0px;
  }
}
@media (min-width:1400px){
  .search_btn {
    padding: 21px 40px;
    border-radius: 0;
    content: '';
    left: 1173px;
    top: 0px;
    border-radius: 0 40px 40px 0px;
  }
}