.banner{
  height: 400px;
}
.banner h1, .banner h4{
  color: #009344;
}
.b-img{
  background-image: url(../../Assets/Banner5.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 100px 0 !important;
}

@media (max-width: 575px) {
   .b-img{
    background-image: url(../../Assets/mobileBanner.png);
    overflow: hidden;
    margin-bottom: -100px;
   }
}
@media (min-width:576px) {
  .b-img{
    background-image: url(../../Assets/Banner5.png);
    margin-bottom: 0px;

   }
  
}