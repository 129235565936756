.inquiry {
  background: #009344;
}

.inquiry_btn {
  color: #ffffff !important;
  background-color: transparent  !important;
  font-family: "Inria Sans";
  font-size: 30px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  width: 70%;
  padding: 8px 0px;
  font-size: 28px;
}
.inquiry_btn a {
  text-decoration: none !important;
  color: #ffffff !important;
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: white;
  border-color: white;
}

.inquiry-title {
  color: #ffffff;
  text-align: center;
  font-family: "Inria Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.inquiry_btn span{
  margin-left: 5px;
  display: block;
  font-size: 16px;
}
@media (min-width:360px){
  .inquiry-title {
    font-size: 21px;
}
}
@media (max-width: 575px) {
  .inquiry_btn {
    width: 100%;
  }

  .inquiry-title {
    font-size: 21px;
  }
}
@media (min-width:320px){
  .inquiry-title {
    font-size: 18px;
}
.inquiry_btn {
  font-size: 18px !important;
}
.inquiry_btn svg{
  width: 24px;
  height: 24px;
}
}
@media (min-width:350px){
  .inquiry-title {
    font-size: 20px;
}
.inquiry_btn {
  font-size: 20px !important;
}
.inquiry_btn svg{
  width: 24px;
  height: 20px;
}

}
@media (min-width:375px) {
  .sb-80 {
    padding-bottom: 80px !important;
}
.inquiry-title {
  font-size: 18px;
}
.inquiry_btn {
  width: 100%;
  font-size: 15px !important;
}
}
@media (min-width:576px){
  .inquiry-title {
    font-size: 32px;
}
  
}
@media (min-width:575px){
  .inquiry-title {
    font-size: 32px;
}
.inquiry_btn svg{
  width: 34px;
  height: 34px;
}
}
@media (min-width:560px) {
  .sb-80 {
    padding-bottom: 80px !important;
}
.inquiry-title {
  font-size: 28px;
}
.inquiry_btn {
  width: 70%;
  font-size: 28px !important;
}
}
@media (min-width: 768px) {
  .inquiry_btn {
    width: 70%;
    font-size: 28px !important;
  }

  .inquiry-title {
    font-size: 40px;
  }
}

@media (min-width: 992px) {
  .inquiry_btn {
    width: 70%;
    font-size: 23px !important;
  }

  .inquiry-title {
    font-size: 26px;
  }

  .border_input {
    border: 10px solid #7ab0415b;
    border-radius: 100px;
  }
  .sb-80 {
    padding-bottom: 50px !important;
}
.inquiry_btn svg{
  width: 26px;
  height: 26px;
}
}

@media (min-width:1200px) {
  .inquiry_btn {
    width: 70%;
    font-size: 28px !important;
  }

  .inquiry-title {
    font-size: 22px;
  }

  .border_input {
    border: 10px solid #7ab0415b;
    border-radius: 100px;
  }

}
@media (min-width:1400px){
  .inquiry_btn {
    font-size: 20px !important;
  }  
}

/* .jogi_mail{
  border: 2px solid #fff !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important
} */