.logo_img {
  width: 150px;
}
.header_bg {
  background: white;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  border-bottom: none;
  
}
.navbar-collapse .navbar-nav .active {
  color: #7bb041 !important;
}
