.edit_photo{
    border: 5px solid #7BB041;
    /* border-radius: 50%; */
}
.edit_info{
    border: 2px solid #Aeaeae; 
}
.edit_btn{
    background-color: #7BB041;
    width: 100%;
    color: #fff;
    border: none;
    padding: 12px;
}

.logout_btn{
    background-color:rgb(224, 28, 28);
    margin-top: 5px;
    width: 100%;
    color: #fff;
    border: none;
    padding: 12px;
}

.profile-container{
    margin-top: 130px;
}